$primary-color: var(--primary-color);
$primary-color-20: var(--primary-color-20);
$primary-light: var(--primary-light);
$primary-dark: var(--primary-dark);
$primary-dark-20: var(--primary-dark-20);
$primary-dark-95: var(--primary-dark-95);

$secondary-color: var(--primary-dark);

$grey-light: var(--grey-light);
$grey-medium: var(--grey-medium);
$grey-switch: var(--grey-switch);
$grey-hover-switch: var(--grey-hover-switch);

$text-dark: var(--primary-dark);
$text-light: var(--primary-light);

$success: var(--success);
$danger: var(--danger);
