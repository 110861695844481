@import 'reset-css';
@import 'assets/styles/colors.scss';
@import 'assets/styles/variables.scss';
@import 'assets/styles/typography.scss';

:root {
	--primary-color: rgba(242, 79, 75, 1);
	--primary-color-20: rgba(242, 79, 75, 0.2);
	--primary-light: rgba(253, 253, 253, 1);
	--primary-dark: rgba(15, 15, 15, 1);
	--primary-dark-20: rgba(15, 15, 15, 0.2);
	--primary-dark-95: rgba(15, 15, 15, 0.95);
	--secondary-color: var(--primary-dark);
	--grey-light: rgba(226, 226, 226, 1);
	--grey-medium: rgba(153, 153, 153, 1);
	--grey-switch: rgba(217, 217, 217, 1);
	--grey-hover-switch: rgba(195, 193, 193, 1);
	--text-dark: var(--primary-dark);
	--text-light: var(--primary-light);
	--success: rgba(155, 242, 75, 1);
	--danger: rgba(239, 32, 27, 1);
}

html {
	scroll-behavior: smooth;
}

html,
body {
	height: 100%;
	display: flex;
	flex-direction: column;
}

body.dark {
	--primary-light: rgba(17, 17, 17, 1);
	--primary-dark: rgba(242, 241, 241, 1);
	--secondary-color: var(--primary-dark);
	--grey-light: rgba(53, 52, 52, 1);
	--grey-medium: rgba(153, 153, 153, 1);
	--grey-switch: rgba(82, 82, 82, 1);
	--grey-hover-switch: rgba(195, 193, 193, 1);
	--text-dark: var(--primary-dark);
	--text-light: var(--primary-light);
}

body {
	&::-webkit-scrollbar {
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		background-color: var(--grey-light);
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--grey-medium);
		border-radius: 5px;
	}
}

#root {
	flex: 1;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

p,
h3,
h4 {
	a {
		&:link,
		&:visited {
			text-decoration: none;
			color: var(--text-dark);
		}

		&:link {
			position: relative;
			@include transition;

			&::after {
				position: absolute;
				bottom: 0;
				left: 0;
				content: '';
				height: 2px;
				width: 100%;
				background-color: var(--primary-color);
				@include transition;
			}
		}

		&:hover {
			color: var(--primary-color);

			&::after {
				bottom: 100%;
			}
		}

		@include focus;
	}
}

strong {
	font-weight: $font-bold;
}

button {
	&:hover {
		cursor: pointer;
	}
}
